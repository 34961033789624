import React, {useState} from "react";
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import apiRequest from "../../api/apiRequest";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import slashScreen from "../../assets/img/splash-screen.png";

const RequiresMoreDetailsModal = () => {
    const token = useSelector(store => store.auth.token);
    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState<{
        [key: string]: string[]
    }>({})

    const [updateClientWithRequiredDetailsForm, setUpdateClientWithRequiredDetailsForm] = useState({
        line_1: "",
        line_2: "",
        city: "",
        county: "",
        postcode: "",
        phone: "",
        how_did_you_hear: "",
    })

    const updateClientWithRequiredDetails = async (event: { preventDefault: () => void; }) => {
        event.preventDefault()

        setErrors([])
        setLoading(true)

        try {
            await apiRequest('update-client-with-required-details', {
                method: 'POST',
                params: {},
                data: updateClientWithRequiredDetailsForm,
                token
            });

            history.go(0)
        } catch (errors) {
            setErrors(errors.response.data.errors)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            isOpen={true}
            centered={true}
            className="position-relative"
        >
            <img
                src={slashScreen}
                alt="splash-screen"
                className="position-absolute w-100"
                style={{opacity: 0.3}}
            />

            <ModalHeader
                className="font-lg border-0 justify-content-center pb-0"
                style={{zIndex: 0}}
            >
                <h2 className="mb-0 font-weight-bold">ACCOUNT SETUP</h2>
            </ModalHeader>

            <ModalBody className="pt-0">
                <p className="border-0 text-center font-sm font-weight-semibold">
                    Enter your details below:
                </p>

                {
                    Object.values(errors).map((error) => (
                        error.map(message => (
                            <p className="text-center text-danger">
                                { message }
                            </p>
                        ))
                    ))
                }

                <Form onSubmit={updateClientWithRequiredDetails}>
                    <FormGroup row>
                        <Col className="pr-0">
                            <Label for="line_1">Address Line 1 *</Label>
                            <Input
                                type="text"
                                name="line_1"
                                onChange={(event) => setUpdateClientWithRequiredDetailsForm({
                                    ...updateClientWithRequiredDetailsForm,
                                    line_1: event.target.value
                                })}
                                required={true}
                            />
                        </Col>

                        <Col>
                            <Label for="line_2">Address Line 2</Label>
                            <Input
                                type="text"
                                name="line_2"
                                onChange={(event) => setUpdateClientWithRequiredDetailsForm({
                                    ...updateClientWithRequiredDetailsForm,
                                    line_2: event.target.value
                                })}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col className="pr-0">
                            <Label for="city">City *</Label>
                            <Input
                                type="text"
                                name="city"
                                onChange={(event) => setUpdateClientWithRequiredDetailsForm({
                                    ...updateClientWithRequiredDetailsForm,
                                    city: event.target.value
                                })}
                                required={true}
                            />
                        </Col>

                        <Col>
                            <Label for="county">County</Label>
                            <Input
                                type="text"
                                name="county"
                                onChange={(event) => setUpdateClientWithRequiredDetailsForm({
                                    ...updateClientWithRequiredDetailsForm,
                                    county: event.target.value
                                })}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col>
                            <Label for="postcode">Postcode *</Label>
                            <Input
                                type="text"
                                name="postcode"
                                onChange={(event) => setUpdateClientWithRequiredDetailsForm({
                                    ...updateClientWithRequiredDetailsForm,
                                    postcode: event.target.value
                                })}
                                required={true}
                            />
                        </Col>

                        <Col>
                            <Label for="postcode">Phone Number *</Label>
                            <Input
                                type="text"
                                name="phone"
                                onChange={(event) => setUpdateClientWithRequiredDetailsForm({
                                    ...updateClientWithRequiredDetailsForm,
                                    phone: event.target.value
                                })}
                                required={true}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Col>
                            <Label for="how_did_you_hear">How did you hear about us? *</Label>
                            <Input
                                type="text"
                                name="how_did_you_hear"
                                onChange={(event) => setUpdateClientWithRequiredDetailsForm({
                                    ...updateClientWithRequiredDetailsForm,
                                    how_did_you_hear: event.target.value
                                })}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup className="mb-0">
                        <Col xs={{size: 6, offset: 3}}>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={loading}
                                className="w-100"
                            >
                                Submit
                            </Button>
                        </Col>
                    </FormGroup>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default RequiresMoreDetailsModal;